import React from 'react';
import OwnerIntro from './ownerIntro';
import TeamAll from './teamAll';
import CustomerReviews from './customerReviews';

const TeamPage = () => {
  return (
    <>
      {/* <div className='headerContainer'>
        <h1>
          <span className='yellow'>Bowman Automobile AG</span> kurz vorgestellt
        </h1>
      </div> */}
      <OwnerIntro />
      <TeamAll />
      <CustomerReviews />
    </>
  );
};

export default TeamPage;
