import React from 'react';
import * as styles from './owner.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwissFlag from '../../images/flag/switzerland.png';
import GermanFlag from '../../images/flag/germany.png';
import EnglishFlag from '../../images/flag/english.png';

const getImage = graphql`
  {
    file(relativePath: { eq: "team/Deon_new.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 500, placeholder: BLURRED)
      }
    }
  }
`;

const miloAge = new Date().getFullYear() - 2001;
const robinAge = new Date().getFullYear() - 2008;
const deonAge = new Date().getFullYear() - 1970;

const TeamPage = () => {
  const data = useStaticQuery(getImage);

  return (
    <section className='pt-6'>
      <div
        className={`container-fluid justify-content-center ${styles.ownerContainer}`}
      >
        <div className={`row ${styles.row}`}>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-8'>
            <div className='headerContainer'>
              <h1>
                <span className='yellow'>Bowman Automobile AG</span> kurz
                vorgestellt
              </h1>
            </div>
            <h5 className={`row ${styles.ownerInformation}`}>
              <span className='spanBold'>Deon D. Bowman </span>In Cape Town
              (South-Africa) geboren und in der schönen Schweiz aufgewachsen
              habe ich schon vieles dieser Welt gesehen. Viele Kulturen kennen
              gelernt und viele interessante Begegnungen erlebt, und freue mich
              im privaten sowie geschäftlichen Umfeld immer wieder auf Neues.
              Mit der Mischung Afrika/Schweiz verfüge ich über eine Kombination
              eines seriösen und sehr zuverlässigen Geschäftsmannes, und nach
              dem Motto "Das Leben ist zu kurz um schlechten Wein zu trinken"
              aber auch einer der gerne die Seele mal baumeln lässt und die
              schönen Seiten des Leben geniesst. <br />{' '}
              <span className='spanBold'>
                Über 30 Jahre Erfahrung in der Automobilbranche:
              </span>{' '}
              In den Jahren 1986 bis 2013 vom Automechaniker-Lehrling über den
              Ersatzteillager-Leiter, Verkäufer und Verkaufsleiter bis hin zum
              Geschäftsführer. Seit 2013 Selbständig im Fahrzeughandel. 30
              Jahre, das kann kein Zufall sein, das muss Leidenschaft sein,
              entsprechend gehe ich auch heute noch jeden Tag mit grosser
              Freude, Begeisterung, Zufriedenheit und Einsatz an meine tägliche
              Arbeit. Heute profitieren SIE von dieser langjährigen Erfahrung,
              gerne vermittle ich Ihnen wertvolle Informationen aus einem
              Business das viele Seiten hat. <br />{' '}
              <span className='spanBold'>{deonAge} Jahre Lebenserfahrung:</span>{' '}
              Vom Schüler über den Teenager bis hin zum heutigen Ehemann und
              stolzen Vater zweier Söhne ({miloAge} und {robinAge} Jahre). Am
              meisten Wert lege ich auf: Zuverlässigkeit, Pünktlichkeit sowie
              Ordnung & Sauberkeit.
            </h5>
          </div>
          <div
            className={`col-sm-8 col-md-8 col-lg-6 col-xl-4 ${styles.introduction}`}
          >
            <div className={`p-0 ${styles.ownerImgContainer}`}>
              <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} />
            </div>
            <div className={styles.info}>
              <span className='spanBold'>Deon D. Bowman</span>
              <span>Inhaber Geschäftsführer</span>
              <span className='spanBold'>Sprachkenntnisse:</span>
              <div className={styles.flagContainer}>
                <img className={`img-fluid`} src={SwissFlag} alt='Swiss flag' />
                <img
                  className={`img-fluid`}
                  src={GermanFlag}
                  alt='German flag'
                />
                <img
                  className={`img-fluid`}
                  src={EnglishFlag}
                  alt='English flag'
                />
              </div>
              <span className='spanBold'>Hobbys:</span>
              <span>Familie, Tennis, Golf, Motorsport</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamPage;
