import React from 'react';
import TeamPage from '../components/TeamPage/teamPage';
import Layout from '../components/layout';
import SEO from '../components/SEO/SEO';

const Team = () => {
  return (
    <Layout>
      <SEO
        title='Team'
        description='Bowman Automobile AG team hilft Ihnen gerne mit einer professionellen Reparatur Ihres Autos.'
      />
      <TeamPage />
    </Layout>
  );
};

export default Team;
