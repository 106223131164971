import React from 'react';
import * as styles from './customerReviews.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import reviews from '../../config/libs/reviews';

const CustomerReviews = () => {
  return (
    <section className={styles.customerReviews}>
      <div className='headerContainer'>
        <h1>
          Das sagen Kunden über <span className='yellow'>uns</span>
        </h1>
      </div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          {Object.values(reviews).map((item) => (
            <div className={`col-sm col-md-6 col-lg-4 ${styles.reviewBox}`}>
              <FontAwesomeIcon icon={faQuoteRight} className={`yellow`} />
              <div>
                <p>{item.content}</p>
              </div>
              <span className='yellow'>{item.date}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CustomerReviews;
