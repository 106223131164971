// extracted by mini-css-extract-plugin
export var buttonMd = "teamAll-module--button-md--2ff09";
export var carousel = "teamAll-module--carousel--06e4c";
export var grey = "teamAll-module--grey--20486";
export var greyFont = "teamAll-module--greyFont--2413f";
export var headerContainer = "teamAll-module--headerContainer--b8d0e";
export var image = "teamAll-module--image--82934";
export var spanBold = "teamAll-module--spanBold--30ce2";
export var teamAllImgContainer = "teamAll-module--teamAllImgContainer--d3d9a";
export var teamContainer = "teamAll-module--teamContainer--b7a1c";
export var teamInfo = "teamAll-module--teamInfo--25dfc";
export var teamMembersSection = "teamAll-module--teamMembersSection--f1a63";
export var yellow = "teamAll-module--yellow--069ed";