// extracted by mini-css-extract-plugin
export var buttonMd = "owner-module--button-md--390d8";
export var carousel = "owner-module--carousel--01fb3";
export var flagContainer = "owner-module--flagContainer--585ae";
export var grey = "owner-module--grey--34b08";
export var greyFont = "owner-module--greyFont--d16f9";
export var headerContainer = "owner-module--headerContainer--cb1f4";
export var info = "owner-module--info--f8ae9";
export var introduction = "owner-module--introduction--673ed";
export var ownerContainer = "owner-module--ownerContainer--23fd7";
export var ownerImgContainer = "owner-module--ownerImgContainer--1358b";
export var ownerInformation = "owner-module--ownerInformation--cc83b";
export var row = "owner-module--row--a3cf3";
export var spanBold = "owner-module--spanBold--74cfe";
export var yellow = "owner-module--yellow--d03c3";